<template>
  <v-footer
    absolute
  >
    <v-spacer />
    <span class="font-weight-light">v {{ env.VUE_APP_VERSION }}</span>
  </v-footer>
</template>

<script>
  export default {
    data: () => ({
      env: process.env,
    }),
    name: `CoreFooter`,
  }
</script>